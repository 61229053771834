<template>
        <div class="page page--pay">
            <Header></Header>
            <div class="page__content">
                <div class="report">
                    <strong>您的报告摘要</strong>
                    <ul>
                        <li class="placeholder">答题数<span class="text">{{ order.questions_count }}题</span></li>
                        <li class="placeholder">完成时间<span class="text">{{ order.completed_at }}</span></li>
                        <li class="placeholder">用时<span class="text">{{ order.duration_seconds | secondsToDHMS }}</span></li>
                        <li class="placeholder">性别<span class="text">{{ order.gender | gender }}</span></li>
                        <li class="placeholder">年龄<span class="text">{{ order.age | age }}</span></li>
                    </ul>
                    <ol v-if="order.order_type == 1">
                        <li>专属测评报告及抑郁分值<span class="lock">待解锁</span></li>
                        <li>我的症状解析及改善建议<span class="lock">待解锁</span></li>
                        <li>抑郁情绪减压26发<span class="lock">待解锁</span></li>
                        <li>十死一生—战胜抑郁分享<span class="lock">待解锁</span></li>
                        <li>100本+心理治愈经典免费<span class="lock">待解锁</span></li>
                        <li>专家交流及心理知识分享<span class="lock">待解锁</span></li>
                    </ol>
                </div>
                <div class="pay_channels">
                    <div class="pay_channels__item pay_channels__item--Weixin" @click="handlePay('Weixin')">微信支付</div>
                    <div class="pay_channels__item pay_channels__item--Alipay" @click="gotoOneonone()">限时特惠（含老师一对一解答）</div>
                </div>
                <p class="tips">
                    你的测评已完成！<br>
                    支付完成后查看测评结果<br>
                    已有 <dfn>876960</dfn> 人多与测试<br>
                    <dfn>98.6%</dfn> 的用户感觉很有用
                </p>
            </div>
        </div>
</template>

<script>
import Header from '../components/Header.vue'

export default {
    name: 'Pay',
    components: {
        Header
    },
    data() {
        return {
            order: {}
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            this.$api.post('/order/info', { order_no: this.$route.query.order_no }).then(res => {
                if (res.data.code === 0) {
                    this.order = res.data.data
                    this.startGenerating()
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        },
        handlePay(code) {
            this.$router.push({
                path: 'paying',
                query: {
                    order_no: this.order.order_no,
                    pay_channel_code: code
                }
            })
        },
        gotoOneonone() {
            this.$router.push({
                path: 'oneonone',
                query: {
                    order_no: this.order.order_no
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/pay.scss';
</style>
