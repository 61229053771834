<template>
    <div class="page generating">
        <div class="generating__header">
            <div class="generating__header-icon">
                <img src="@/assets/images/generating.png">
            </div>
            <strong>您的专业报告正在生成中……</strong>
        </div>
        <div class="generating__content">
            <div class="tabs">
                <div :class="{ tabs__item: true, 'tabs__item--active': generatingTabIndex == 0 }">结果汇总</div>
                <div :class="{ tabs__item: true, 'tabs__item--active': generatingTabIndex == 1 }">报告分析</div>
                <div :class="{ tabs__item: true, 'tabs__item--active': generatingTabIndex == 2 }">生成报告</div>
            </div>
            <div class="items">
                <div class="items__item" v-for="(item, i) in generatingItems" :key="i">
                    {{ item.name }}
                    <div class="progress-bar">
                        <div class="progress-bar__out">
                            <span class="progress-bar__in" :style="{ width: item.progress + '%' }"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Generating',
    data() {
        return {
            generatingTabIndex: -1,
            generatingItems: [],
            generatingIntervalId: null
        }
    },
    mounted() {
        this.startGenerating()
    },
    beforeDestroy() {
        this.stopGenerating()
    },
    methods: {
        startGenerating() {
            this.generatingIntervalId = setInterval(() => {
                if (this.generatingItems.length == 0) {
                    this.generatingTabIndex++
                    if (this.generatingTabIndex == 0) {
                        this.generatingItems = [
                            { name: '正在收集你的选项中', progress: 0 },
                            { name: '正在计算匹配结果中', progress: 0 },
                            { name: '正在生成汇总全部结果', progress: 0 }
                        ]
                    } else if (this.generatingTabIndex == 1) {
                        this.generatingItems = [
                            { name: '正在分析你的基础报告内容', progress: 0 },
                            { name: '正在分析整体内容匹配报告详情', progress: 0 }
                        ]
                    } else if (this.generatingTabIndex == 2) {
                        this.generatingItems = [
                            { name: '正在生成你的基础板块内容', progress: 0 },
                            { name: '正在生成你的详细完整报告', progress: 0 },
                            { name: '正在输出你的完整报告中', progress: 0 }
                        ]
                    }
                } else {
                    for (let i = 0; i < this.generatingItems.length; i++) {
                        if (this.generatingItems[i].progress < 100) {
                            let progress = this.generatingItems[i].progress + Math.floor(Math.random() * 20) + 1
                            if (progress > 100) {
                                progress = 100
                            }
                            this.generatingItems[i].progress = progress
                            break
                        }
                    }
                    if (this.generatingItems[this.generatingItems.length - 1].progress == 100) {
                        this.generatingItems = []
                        if (this.generatingTabIndex == 2) {
                            this.stopGenerating()
                            this.$router.push({
                                path: 'pay2',
                                query: {
                                    order_no: this.$route.query.order_no
                                }
                            })
                        }
                    }
                }
            }, 100)
        },
        stopGenerating() {
            if (this.generatingIntervalId != null) {
                clearInterval(this.generatingIntervalId)
                this.generatingIntervalId = null
                this.generatingTabIndex = -1
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/generating.scss';
</style>
