<template>
    <div class="page page--wxoa_pay" v-if="ready">
        <div class="page__header">
            <strong>{{ order.subject }}</strong>
            <div class="money"><dfn>&yen;</dfn>{{ order.order_amount }}</div>
        </div>
        <div class="page__content">
            <div class="payee">
                <div class="payee-item">
                    <label>收款方</label>
                    <b>乾森心理</b>
                </div>
            </div>
            <div class="commit">
                <button type="button" @click="onBridgeReady">立即支付</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WxoaPay',
    data() {
        return {
            ready: false,
            order: {},
            payParams: null,
            outTradeNo: null,
            checkPayIntervalId: null
        }
    },
    beforeCreate() {
        this.createWeixinJSBridgeReady()
    },
    beforeDestroy() {
        this.stopCheckPay()
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        createWeixinJSBridgeReady() {
            if (typeof window.WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                    document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
                }
            }
        },
        onBridgeReady() {
            if (!this.payParams) {
                this.handlePay()
            }
            window.WeixinJSBridge.invoke('getBrandWCPayRequest', this.payParams, function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                }
            });
        },
        fetchData() {
            this.$api.post('/order/info', { order_no: this.$route.query.order_no }).then(res => {
                if (res.data.code === 0) {
                    this.ready = true
                    this.order = res.data.data
                    this.startCheckPay()
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        },
        handlePay() {
            this.$api.post('/pay/wxoa', {
                order_no: this.$route.query.order_no,
                url: window.location.href
            }).then(res => {
                if (res.data.code === 0) {
                    this.outTradeNo = res.data.data.out_trade_no
                    this.payParams = res.data.data.pay_params
                    this.onBridgeReady()
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        },
        checkPay() {
            this.$api.post('/pay/paid', {
                order_no: this.$route.query.order_no
            }).then(res => {
                if (res.data.code == 0) {
                    if (res.data.data.is_paid) {
                        this.paySuccess(res.data.data.is_with_oneonone)
                    }
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        },
        startCheckPay() {
            this.checkPayIntervalId = setInterval(() => {
                this.checkPay()
            }, 2000)
        },
        stopCheckPay() {
            if (this.checkPayIntervalId != null) {
                clearInterval(this.checkPayIntervalId)
                this.checkPayIntervalId = null
            }
        },
        checkIfWeChat() {
            const ua = navigator.userAgent.toLowerCase();
            return ua.indexOf('micromessenger') !== -1;
        },
        paySuccess(is_with_oneonone) {
            if (this.checkIfWeChat()) {
                if (is_with_oneonone == 1) {
                    this.$router.replace({
                        path: '/oneonone/result',
                        query: {
                            order_no: this.$route.query.order_no
                        }
                    })
                } else {
                    this.$router.replace({
                        path: '/result',
                        query: {
                            order_no: this.$route.query.order_no
                        }
                    })
                }
            } else {
                if (is_with_oneonone == 1) {
                    this.$router.replace({
                        path: '/oneonone/success',
                        query: {
                            order_no: this.$route.query.order_no
                        }
                    })
                } else {
                    this.$router.replace({
                        path: '/success',
                        query: {
                            order_no: this.$route.query.order_no
                        }
                    })
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/wxoa_pay.scss';
</style>
