export function gender(val) {
    if (val == 1) {
        return '男'
    } else if (val == 2) {
        return '女'
    }
    return '未知'
}

export function age(val) {
    if (val !== undefined && val !== null && val !== '') {
        return val
    }
    return '未知'
}

export function orderType(val) {
    if (val == 1) {
        return '抑郁与焦虑自评量表(SDS)专业版'
    } else if (val == 2) {
        return '一对一解答'
    } else if (val == 3) {
        return '综合心理健康评估'
    } else if (val == 4) {
        return '人格障碍测试'
    } else if (val == 5) {
        return '心理健康测评-焦虑版'
    }
    return ''
}


export function secondsToDHMS(totalSeconds) {
    const days = Math.floor(totalSeconds / (24 * 60 * 60));
    let remainingSeconds = totalSeconds % (24 * 60 * 60);
    const hours = Math.floor(remainingSeconds / (60 * 60));
    remainingSeconds %= (60 * 60);
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;

    let result = '';

    if (days > 0) {
        result += `${days}天`;
    }

    if (hours > 0 || (days > 0 && minutes > 0)) {
        result += `${hours}小时`;
    }

    if (minutes > 0 || (hours > 0 && seconds > 0)) {
        result += `${minutes}分钟`;
    }

    if (seconds > 0) {
        result += `${seconds}秒`;
    }

    return result;
}