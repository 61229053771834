<template>
    <div class="page page--pay">
        <div class="page__header">
            <strong>评估结果</strong>
        </div>
        <div class="page__content">
            <div class="section section--score">
                <div class="score">
                    <b>{{ order.total_score }}</b>
                </div>
                <p class="text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ result.summary }}
                </p>
            </div>
            <div v-if="order.order_type == 1">
                <div class="section">
                    <div class="section__heading">
                        <h3>关于抑郁</h3>
                    </div>
                    <p class="text">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中国精神卫生调查显示，我国成人抑郁障碍终生患病率为6.8%，其中抑郁症为3.4%，目前我国患抑郁症人数9500万，每年大约有28万人自杀，其中40%患有抑郁症。<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;世界卫生组织(WHO)统计，全球约10亿人正在遭受精神障碍困扰，每40秒就有一人因自杀而失去生命，低收入和中等收入国家的自杀人数占全球自杀人数的77%。<br>
                        新冠疫情后，全球精神障碍疾病负担更加沉重，重度抑郁症和焦虑症的病例分别增加了28%和26%，抑郁症患者激增5300万，增幅高达27.6%。疫情的压力，给抑郁症的诊断及治疗带来了更大的挑战。<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2021年发表于《柳叶刀》上的研究提到，新冠大流行使得公共卫生安全因素变得突出，但其他和不平等相关的复杂社会因素并未消失，此前已有研究显示，疫情给妇女和年轻人带来的影响最严重。<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;同时，国民对于抑郁症的了解还知之甚少，抑郁症患者依然面临着病耻感强，疾病教育缺失，就医困难等问题。
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>什么是抑郁症</h3>
                    </div>
                    <p class="text">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;抑郁症是世界第四大疾病，但到2020年将上升为世界第二大疾病；到目前为止，抑郁症的病因还不是很清楚，但可以肯定的是，生物、心理、社会环境等多种因素参与了抑郁症的发病过程。生物学因素主要涉及遗传、神经生化、神经内分泌、神经再生等方面。与抑郁症密切相关的心理易感品质是病前人格特征，如抑郁气质。成年期的应激生活事件是临床显著抑郁发作的重要触发条件。但这些因素并不是单独起作用的，强调遗传与环境或应激因素的交互作用，这种交互作用的发生时间对抑郁症的发生有重要影响。
                    </p>
                </div>
                <div class="oneonone">
                    <router-link :to="{ path: '/oneonone/', query: { order_no: order.order_no } }"><img
                            src="@/assets/images/oneonone_banner.png"></router-link>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>抑郁症的预防</h3>
                    </div>
                    <p class="text">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;有人对抑郁症患者随访10年，发现75%~80%的患者多次复发，因此抑郁症患者需要预防性治疗。3次以上发作应长期治疗，甚至终身服用药物。多数学者认为维持治疗药物的剂量应与治疗相同，并定期进行门诊随访观察。心理治疗和社会支持系统在预防本病复发方面也起着非常重要的作用。要尽可能缓解或减轻患者过重的心理负担和压力，帮助患者解决生活、工作中的实际困难和问题，提高患者的应对能力，积极为其创造良好的环境，防止复发。
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>无助感解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['1'] }}<br><br>
                        {{ result.category_summaries['1'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>自杀倾向解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['2'] }}<br><br>
                        {{ result.category_summaries['2'] }}
                    </p>
                </div>
                <div class="oneonone">
                    <router-link :to="{ path: '/oneonone/', query: { order_no: order.order_no } }"><img
                            src="@/assets/images/oneonone_banner.png"></router-link>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>依恋关系解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['3'] }}<br><br>
                        {{ result.category_summaries['3'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>自我厌恶解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['4'] }}<br><br>
                        {{ result.category_summaries['4'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>抑郁症状解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['5'] }}<br><br>
                        {{ result.category_summaries['5'] }}
                    </p>
                </div>
            </div>
            <div v-else-if="order.order_type == 3 || order.order_type == 5">
                <div class="section">
                    <div class="section__heading">
                        <h3>躯体化解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['1'] }}<br><br>
                        {{ result.category_summaries['1'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>强迫症状解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['2'] }}<br><br>
                        {{ result.category_summaries['2'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>人际关系敏感解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['3'] }}<br><br>
                        {{ result.category_summaries['3'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>抑郁解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['4'] }}<br><br>
                        {{ result.category_summaries['4'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>焦虑解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['5'] }}<br><br>
                        {{ result.category_summaries['5'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>敌对解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['6'] }}<br><br>
                        {{ result.category_summaries['6'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>恐怖解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['7'] }}<br><br>
                        {{ result.category_summaries['7'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>偏执解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['8'] }}<br><br>
                        {{ result.category_summaries['8'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>精神病性解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['9'] }}<br><br>
                        {{ result.category_summaries['9'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>其他解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['10'] }}<br><br>
                        {{ result.category_summaries['10'] }}
                    </p>
                </div>
            </div>
            <div v-else-if="order.order_type == 4">
                <div class="section">
                    <div class="section__heading">
                        <h3>边缘型人格障碍解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['1'] }}<br><br>
                        {{ result.category_summaries['1'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>自恋型人格障碍解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['2'] }}<br><br>
                        {{ result.category_summaries['2'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>回避型人格障碍解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['3'] }}<br><br>
                        {{ result.category_summaries['3'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>依赖型人格障碍解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['4'] }}<br><br>
                        {{ result.category_summaries['4'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>表演型人格障碍解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['5'] }}<br><br>
                        {{ result.category_summaries['5'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>强迫型人格障碍解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['6'] }}<br><br>
                        {{ result.category_summaries['6'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>分裂型人格障碍解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['7'] }}<br><br>
                        {{ result.category_summaries['7'] }}
                    </p>
                </div>
                <div class="section">
                    <div class="section__heading">
                        <h3>偏执型人格障碍解析</h3>
                    </div>
                    <p class="text">
                        分值：{{ order.category_scores['8'] }}<br><br>
                        {{ result.category_summaries['8'] }}
                    </p>
                </div>
            </div>
            <div class="footer-floating footer-floating--oneonone">
                <div class="footer-floating__wrap">
                    <router-link :to="{ path: '/oneonone/', query: { order_no: order.order_no } }"><img
                            src="@/assets/images/oneonone_banner2.png"></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Result',
    data() {
        return {
            order: {
                total_score: 0,
                category_scores: {}
            },
            result: {
                category_summaries: {}
            }
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            this.$api.post('/order/result', { order_no: this.$route.query.order_no }).then(res => {
                if (res.data.code === 0) {
                    if (res.data.data == null) {
                        this.$router.replace('/')
                    } else {
                        this.order = res.data.data.order
                        this.result = res.data.data.result
                    }
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/result.scss';
</style>
