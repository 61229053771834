<template>
    <div class="page page--success" v-if="openLink">
        <div class="jump-image">
            <img src="@/assets/images/jumpImage.png" />
        </div>
        <div class="jump-text">正在跳转中，请稍后...</div>
        <a class="jump-button" :href="openLink">打开微信添加老师</a>
    </div>
</template>

<script>
export default {
    name: 'OneononeSuccess',
    data() {
        return {
            openLink: null
        }
    },
    mounted() {
        this.launchWeixin()
    },
    methods: {
        launchWeixin() {
            this.$api.post('/weixin/launch', { order_no: this.$route.query.order_no }).then(res => {
                if (res.data.code === 0) {
                    if (res.data.data != null) {
                        this.openLink = res.data.data.open_link
                    } else {
                        alert('一对一老师信息获取失败，请稍后重试。')
                    }
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/oneonone/success.scss';
</style>
