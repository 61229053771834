var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page page--pay"},[_c('Header'),_c('div',{staticClass:"page__content"},[_c('div',{staticClass:"report"},[_c('strong',[_vm._v("您的报告摘要")]),_c('ul',[_c('li',{staticClass:"placeholder"},[_vm._v("答题数"),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.order.questions_count)+"题")])]),_c('li',{staticClass:"placeholder"},[_vm._v("完成时间"),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.order.completed_at))])]),_c('li',{staticClass:"placeholder"},[_vm._v("用时"),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("secondsToDHMS")(_vm.order.duration_seconds)))])]),_c('li',{staticClass:"placeholder"},[_vm._v("性别"),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("gender")(_vm.order.gender)))])]),_c('li',{staticClass:"placeholder"},[_vm._v("年龄"),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("age")(_vm.order.age)))])])]),(_vm.order.order_type == 1)?_c('ol',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]):_vm._e()]),_c('div',{staticClass:"pay_channels"},[_c('div',{staticClass:"pay_channels__item pay_channels__item--Weixin",on:{"click":function($event){return _vm.handlePay('Weixin')}}},[_vm._v("微信支付")]),_c('div',{staticClass:"pay_channels__item pay_channels__item--Alipay",on:{"click":function($event){return _vm.gotoOneonone()}}},[_vm._v("限时特惠（含老师一对一解答）")])]),_vm._m(6)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("专属测评报告及抑郁分值"),_c('span',{staticClass:"lock"},[_vm._v("待解锁")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("我的症状解析及改善建议"),_c('span',{staticClass:"lock"},[_vm._v("待解锁")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("抑郁情绪减压26发"),_c('span',{staticClass:"lock"},[_vm._v("待解锁")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("十死一生—战胜抑郁分享"),_c('span',{staticClass:"lock"},[_vm._v("待解锁")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("100本+心理治愈经典免费"),_c('span',{staticClass:"lock"},[_vm._v("待解锁")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("专家交流及心理知识分享"),_c('span',{staticClass:"lock"},[_vm._v("待解锁")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"tips"},[_vm._v(" 你的测评已完成！"),_c('br'),_vm._v(" 支付完成后查看测评结果"),_c('br'),_vm._v(" 已有 "),_c('dfn',[_vm._v("876960")]),_vm._v(" 人多与测试"),_c('br'),_c('dfn',[_vm._v("98.6%")]),_vm._v(" 的用户感觉很有用 ")])
}]

export { render, staticRenderFns }