<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    this.setHtmlFontSize()
  },
  mounted() {
    window.addEventListener("resize", this.setHtmlFontSize)
    window.addEventListener("DOMContentLoaded", this.setHtmlFontSize)
  },
  beforeDestroy() {
    window.removeEventListener("DOMContentLoaded", this.setHtmlFontSize)
    window.removeEventListener("resize", this.setHtmlFontSize)
  },
  methods: {
    setHtmlFontSize() {
      let width = document.body.clientWidth
      let fontsize
      if (width <= 240) {
        fontsize = 12.8
      } else if (width >= 640) {
        fontsize = 34.1333
      } else {
        fontsize = (width / 18.75).toFixed(4)
      }
      document.getElementsByTagName("html")[0].style.fontSize = `${fontsize}px`
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/app.scss';
</style>
