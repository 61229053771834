<template>
    <div class="page page--pay2">
        <Header></Header>
        <div class="page__content">
            <p class="top_tips">
                本测评为医用专业量表，你将获得抑郁状况，人际关系，强迫症状，焦虑程度等多项专业分析报告与建议，
            </p>
            <p class="top_tips2">
                三甲医院同款测试，多维度权威分析，爱自己、从关注心理健康、促进自我成长开始!
            </p>
            <div class="packages">
                <div :class="{ package: true, active: !isWithOneonone }" @click="handlePackage(false)">
                    <strong>完整测试报告</strong>
                    <div class="money">
                        <b>&yen;{{ order.order_amount }}</b><del>&yen;58</del>
                    </div>
                    <p>
                        只看完整测试报告，包含抑郁与焦虑状况等多项结果。
                    </p>
                </div>
                <div :class="{ package: true, active: isWithOneonone }" @click="handlePackage(true)">
                    <div class="badge">92%客户选择</div>
                    <strong>报告+倾诉咨询</strong>
                    <div class="money">
                        <b>&yen;{{ oneononeMoney }}</b><del>&yen;129</del>
                    </div>
                    <p>
                        完整测试报告+向心理咨询师提问，并获得专业咨询师的倾述解答。
                    </p>
                </div>
            </div>
            <dl>
                <dt>支付项目：</dt>
                <dd>{{ order.order_type | orderType }}</dd>
                <dt>订单号：</dt>
                <dd>{{ $route.query.order_no }}</dd>
            </dl>
            <div class="pay_channels">
                <div class="pay_channels__item pay_channels__item--Weixin" @click="handlePay('Weixin')">微信支付</div>
            </div>
            <p class="tips">
                你的测评已完成！<br>
                支付完成后查看测评结果<br>
                已有 <dfn>876960</dfn> 人多与测试<br>
                <dfn>98.6%</dfn> 的用户感觉很有用
            </p>
        </div>
    </div>
</template>

<script>
import Header from '../components/Header.vue'

export default {
    name: 'Pay',
    components: {
        Header
    },
    data() {
        return {
            isWithOneonone: false,
            oneononeMoney: 0.1,
            order: {}
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            this.$api.post('/order/info_with_oneonone', { order_no: this.$route.query.order_no }).then(res => {
                if (res.data.code === 0) {
                    this.order = res.data.data.order
                    this.oneononeMoney = res.data.data.oneonone_money
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        },
        handlePackage(isWithOneonone) {
            this.isWithOneonone = isWithOneonone;
        },
        handlePay(code) {
            if (this.isWithOneonone) {
                this.$router.push({
                    path: 'oneonone',
                    query: {
                        order_no: this.order.order_no
                    }
                })
            } else {
                this.$router.push({
                    path: 'paying',
                    query: {
                        order_no: this.order.order_no,
                        pay_channel_code: code
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/pay2.scss';
</style>
